import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../theme/utils/get-rgb';

export const Overflow = styled.div``;

export const Wrapper = styled.div``;

export const Inner = styled.div`
    height: 35rem;
    position: relative;
`;
export const FullScreen = styled.div(
    ({ theme }) => css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: ${theme.palette.background.body};
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
);

export const FullScreenTrigger = styled.div(
    ({ theme }) => css`
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 4rem;
        height: 4rem;
        display: none;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        z-index: 500;
        cursor: pointer;
        background-color: ${theme.palette.color.secondary.opacity.low};
        transition: all ${theme.base.transition};

        @media ${theme.breakpoints.vl.min} {
            display: flex;
        }

        &:hover {
            background-color: ${theme.palette.color.secondary.main};
        }

        i {
            color: ${theme.palette.common.textInverse};
        }
    `,
);

export const MarkerArea = styled.div.withConfig({
    shouldForwardProp: (prop) => !['left', 'right'].includes(prop),
})<{
    left?: number | undefined;
    right?: number | undefined;
}>`
    ${({ theme, left, right }) => css`
        position: absolute;
        top: 3rem;
        bottom: 5rem;
        background-color: ${getOpacity(theme.palette.color.secondary.main, 0.2)};
        z-index: 100;
        pointer-events: none;

        ${left &&
        right &&
        css`
            left: ${left + 90 || 0}px;
            width: ${right - left || 0}px;
        `}
    `}
`;

export const ChartWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const TooltipList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
`;
